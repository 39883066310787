import { Button as ChakraButton, type ChakraComponent } from "@chakra-ui/react";

// This is a fix to an issue happening when google translate is used
// https://github.com/facebook/react/issues/11538#issuecomment-390386520
// https://github.com/chakra-ui/chakra-ui/issues/6004

type Button = ChakraComponent<"div", Record<string, unknown>>;

export const Button = ({
	children,
	loadingText,
	...props
}: {
	children: React.ReactNode;
	loadingText: string;
	isLoading: boolean;
}) => {
	const wrappedLoadingText = <span>{loadingText}</span>;

	const wrappedChildren = <span>{children}</span>;

	return (
		<ChakraButton loadingText={wrappedLoadingText} {...props}>
			{wrappedChildren}
		</ChakraButton>
	);
};

export default Button;
